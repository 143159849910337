import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { LanguageModel } from '../../model/language.model';



@Injectable({
  providedIn: 'root'
})
export class LanguagesService {

  private languageListSource = new BehaviorSubject<LanguageModel[]>([]);
  languageList$ = this.languageListSource.asObservable();

  private selectedLanguageSource = new BehaviorSubject<LanguageModel>(new LanguageModel());
  selectedLanguage$ = this.selectedLanguageSource.asObservable();

  constructor(
    private httpClient: HttpClient,
  ) { }

  getLanguageJSON(): Observable<any> {
    return this.httpClient.get<any>("./../../../../assets/language/language.json");
  }

}
